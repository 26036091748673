.mainContainer {
    width: 482px;
    min-width: 482px;
    background-color: rgb(246, 246, 246);
    margin-top: 40px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 25px;
    border: rgb(173, 173, 173) solid 0.1rem;
    border-radius: 15px;
    box-shadow: rgb(158, 158, 158) 0.5px 2px;
}
.containerTitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}
.process {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
}
.subProcessRow {
    font-weight: 400;
    font-size: 13px;
    padding-left: 3px;
}
.titleBar {
    display: flex;
    flex-direction: row;
}
.titleCol {
    font-weight: 600;
    font-size: 14px;
}