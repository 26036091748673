.riskOptions {
  position: relative;
  margin-right: 3rem;
  margin-top: 0px;
  top: -75px;
}

.riskOption {
  width: 10rem;
  margin-top: 10px;
}

.riskOptionDescr {
  font-size: 14px;
  color: rgb(53, 53, 53);
  user-select: none;
}

.consequenceLabel {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  transform: rotate(270deg);
  position: relative;
  left: 87px;
  top: -42px;
  margin: 0px;
}
.probabilityLabel {
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 1px;
  left: 218px;
  top: 107px;
  position: relative;
}

.cosoCheckBox {
  font-size: 14px;
  cursor: pointer;
}
