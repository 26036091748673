.mainContainer {
  width: 660px;
  padding: 20px;
  border: 5px solid rgb(20, 106, 114);
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
}
.oneControllerContainer {
  display: flex;
  font-size: 12px;
  color: white;
  padding-top: 6px;
  padding-left: 16px;
  background: rgb(27, 127, 136);
  border-radius: 9px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  height: 23px;
  margin-bottom: 10px;
}

.header {
  margin-left: 4px;
  font-size: 20px;
  /* color: white; */
  margin-bottom: 10px;
  font-weight: 600;
}

.iconBox {
  position: absolute;
  left: 650px;
  cursor: pointer;
}

.controllerColumnZero {
  position: absolute;
  left: 210px;
}
.controllerColumnOne {
  position: absolute;
  left: 390px;
}
.controllerColumnTwo {
  position: absolute;
  left: 540px;
}

