.tt input[type="radio"] {
  display: none;
}
.tt input[type="radio"]::before {
  content: "";
  display: inline-block;
  position: relative;
  top: 10px;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 20px;
  margin: 0 0.5em;
}

.tt label::before {
  background-color: white;
  top: 5px;
  content: "";
  display: inline-block;
  border: 1px solid #000;
  border-radius: 50%;
  font-size: 12px;
  position: relative;
  top: 2px;
  margin-right: 7px;
}

.tt input[type="radio"]:checked + label::before {
  background-color: #4077c4;
}

.pushRight {
  margin-right: 20px;
}
.radio3 + label::before {
  width: 1em;
  height: 1em;
}

.rt {
  display: inline;
}

.rtt {
  margin-right: 35px;
  display: inline-block;
}

.pickerClass, input {
  border-radius: 5px;
  border: 1px solid #bbb;
  padding: 2px;
  font-size: 12px;
}
.pickerClass, input :focus {
  outline: none;
}

.selectBoxes {
  font-size: 12px;
      min-width: 100px;
      margin-bottom: 5px;
      padding: 3px;
      cursor: pointer;
      border: thin solid var(--color-select-box);
      border-radius: 5px;
}

.selectBoxes, input :focus {
  outline: none;
}