.formContainer {
  width: 600px;
  margin-bottom: 2rem;
  margin-top: 3.5rem;
}
.textInputRow {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
}

.textInputRow > input {
  width: 320px;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #333;
  margin-right: 14px;
  padding-left: 3px;
  font-size: 13px;
}

.textInputRow > input:focus {
  outline-width: 0px;
  color: #333;
  font-size: 13px;
}

.textInputRow > label {
  width: 135px;
  font-size: var(--labelText);
}
.createPortfolioItem {
  margin-top: 5px;
  width: 400px;
  min-height: 120px;
  line-height: 20px;
  font-family: Arial;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #bbb;
  resize: none; 
}
.createPortfolioItem:active {
  outline-width: 0px;
}
.createPortfolioItem:focus {
  outline-width: 0px;
}