.container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
}
.container {
  margin-top: -10px;
  /* margin-left: 30px; */
  display: flex;
  justify-content: center;
}

.slider {
  position: relative;
  width: 160px;
}

.sliderTrack,
.sliderRange,
.sliderLeftValue,
.sliderRightValue {
  position: absolute;
}

.sliderTrack,
.sliderRange {
  border-radius: 3px;
  height: 5px;
}

.sliderTrack {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.sliderRange {
  background-color: var(--page-header-button-bg);
  z-index: 2;
}

.sliderLeftValue,
.sliderRightValue {
  color: #333;
  font-size: 14px;
  margin-top: -8px;
}

.sliderLeftValue {
  left: -18px;
}

.sliderRightValue {
  right: -20px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 160px;
  outline: none;
}

.thumbLeft {
  z-index: 3;
}

.thumbRight {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: var(--page-header-button-bg);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
/* .thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
} */