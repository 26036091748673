.flexRowContainer {
  display: flex;
  flex-direction: row;
}
.portfolioContainer {
  position: relative;
  top: 12px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  background: rgba(78, 123, 184, 1);
  border-radius: 10px 10px 0px 0px;
}
.portfolioTextContainer {
  letter-spacing: 1.5px;
  font-weight: 350;
  padding-left: 20px;
  display: flex;
  width: 540px;
  flex-direction: column;
  margin-bottom: 7px;
}
.portfolioIconChild {
  align-self: center;
  color: white;
  margin-left: 20px;
  margin-bottom: 7px;
  font-size: 19px;
  cursor: pointer;
}
.portfolioIconChildColumn {
  align-self: center;
  color: white;
  width: 25px;
  margin-left: 20px;
  margin-bottom: 5px;
  font-size: 24px;
  cursor: pointer;
}
.portfolioHeaderChild {
  margin-bottom: 3px;
  cursor: pointer;
  font-size: 19px;
  color: white;
  font-weight: 600;
}
.portfolioTextChild {
  display: flex;
  color: white;
  padding-bottom: 1px;
}
.textItem {
  margin-right: 20px;
}
.portfolioChild {
  width: 100px;  
  height: 100px; 
  background-color: rgb(78,123,184);
  color: white;
  margin-left: 20px;
}