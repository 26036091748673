.newRiskListContainer {
  display: flex;
  margin-bottom: 10px;
}
.newRiskListContainer div {
  font-size: 14px;
  font-weight: 400;
  width: 170px;
  min-width: 170px;
}
.newRiskListContainer div:nth-last-of-type(2), .newRiskListContainer div:nth-last-of-type(1) {
  width: 50px;
  min-width: 50px;
}

.selectBox {
  float: left;
  
  }
  .selectBox label {
    padding-top: 3px;
    font-size: var(--labelText);
  }
  .selectBox select {
    font-size: 12px;
    width: 150px;
    cursor: pointer;
    border: thin solid var(--color-select-box);
    border-radius: 5px;
  }
  .selectBox select:focus {
    outline: none;
  }

  
  .searchInputRow input[type=select] {
    border: 0px;
    width: 150px;
    border: none;
    border-bottom: thin solid var(--color-text-input);
    border-radius: 0px;
    color: rgb(56, 56, 56);
    padding-left: 3px;
  }
  .selectBox input[type=number] {
    width: 32px;
    border: thin solid var(--color-select-box);
    border-radius: 5px;
    color: rgb(56, 56, 56);
    padding-left: 3px;
  }
  
  .searchInputRow input:focus {
    outline: none;
    color: var(--color-text-input-focus);
    border-bottom: thin solid var(--color-text-input);
  }
  .selectBox input:focus {
    outline: none;
    color: var(--color-text-input-focus);
    border-bottom: thin solid var(--color-text-input);
  }

  .riskEntryError {
    /* background-color: greenyellow; */
    margin-bottom: 16px;
    font-size: 12px;
    color: red;
  }