.plotterChart {
    width: 392px;
    min-width: 392px;
    height: 320px;
    margin-right: 35px;
    cursor: pointer;
    margin-top: 10px;
    background-color: rgb(246, 246, 246);
    margin-bottom: 30px;
    padding: 0;
    border: rgb(173, 173, 173) solid 0.1rem;
    border-radius: 15px;
    box-shadow: rgb(158, 158, 158) 0.5px 2px;
}
