.modalContainer {
  transition: all 0.3s ease-in-out;
  display: flex;
  background-color: rgb(52, 58, 64);
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 650px;
  z-index: 5;
  color: #eee;
  padding: 30px 40px;
  width: 820px;
}
.descriptionInput {
  margin-top: 3px;
  padding-left: 4px;
  font-family: Arial;
  height: 95px;
  width: 353px;
  resize: none;
  line-height: 20px;
  border-radius: 5px;
}
.addControllerLabel {
  font-size: 14px;
  font-weight: 450;
}
.fieldRow {
  margin-bottom: 15px;
  font-size: 12px;
  min-width: 100px;
  border-radius: 5px;
}
.fieldRow:disabled {
  background: rgb(82, 90, 97);
  color: white;
  border: none;
}
.iconBox{
  position: absolute;
  left: 850px;
  cursor: pointer;
}
.saveButtonPlacer {
  position: relative;
  left: 345px;
  top: 210px;
}
