.categoryRow {
  display: flex;
}

.categoryRow > div {
  white-space: nowrap;
  padding: 4px 10px 6px 16px;
  color: white;
  border-radius: 7px;
  margin-top: 10px;
  margin-right: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 300;
}

.mainCategory {
  background-color: #1b4988;
}

.subCategory {
  background-color: #4077c4;
}

.commentVerifyCheck {
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-radius: 15px;
  padding: 15px 50px;
  height: 40px;
  color: black;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.commentVerifyCheck h4 {
  padding: 2px;
  margin: 2px;
}

.descriptionInput {
  font-family: Arial;
  margin-top: 3px;
  margin-left: 10px;
  margin-right: 20px;
  width: 190px;
  border: 1px solid rgb(58, 58, 58);
  line-height: 20px;
  outline-width: 0px;
}

.addVerifyCheckButton {
  font-size: 15px;
  width: 130px;
  color: white;
  border-radius: 15px;
  background-color: rgb(23, 80, 155);
  height: 29px;
  margin-right: 10px;
  cursor: pointer;
}

.addVerifyCheckButton:hover {
  background-color: rgb(23, 80, 155, 0.85);
}

.addVerifyCheckButton:active {
  outline-width: 0px;
}
.addVerifyCheckButton:focus {
  outline-width: 0px;
}
.cancelButton {
  font-size: 15px;
  width: 130px;
  color: white;
  border-radius: 15px;
  background-color: rgb(55, 55, 55);
  height: 29px;
  cursor: pointer;
}

.cancelButton:hover {
  background-color: rgb(25, 25, 25);
}

.cancelButton:active {
  outline-width: 0px;
}
.cancelButton:focus {
  outline-width: 0px;
}

.markerIconMedium {
  position: relative;
  left: 0px;
  top: 1px;
  font-size: 15px;
  cursor: pointer;
  color: rgb(207, 207, 207);
  padding-left: 15px;
  padding-right: 0px;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.markerIconMedium:hover {
  color: rgb(112, 112, 112);
}

.titleAddRow {
  display: flex;
  flex-direction: row;
}
