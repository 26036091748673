.cardContainer {
  width: 700px;
  color: black;
  border: 5px solid #146a72;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
  margin-top: 0px;
}

.subHeader {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}
.infoContainer {
  font-size: 14px;
  font-weight: 600;
  padding-left: 20px;
  padding-top: 20px;
  top: 15px;
  left: 20px;
  max-width: 660px;
  width: 660px;
}
.descriptionContainer {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  max-width: 660px;
  width: 660px;
}
.textBox {
  white-space: pre-wrap;
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  resize: none;
  border-color: rgb(200, 200, 200);
  outline-width: 0px;
  width: 646px;
  line-height: 20px;
  margin-bottom: 10px;
}