.formContainer {
  font-size: 13px;
  margin-bottom: 2rem;
  margin-top: 20px;
}

.formButtons {
  margin-bottom: 2rem;
}

.formData {
  display: grid;
  grid-template-columns: 650px 500px;
  grid-template-rows: auto;
  grid-template-areas:
    "name matrix"
    "description matrix"
    "category matrix"
    "process matrix"
    "riskGroup matrix"
    "probability consequence";
}

.matrix {
  grid-area: matrix;
  /* display: flex; */
}

.name {
  grid-area: name;
  min-width: 550px;
}

.description {
  grid-area: description;
}

.category {
  grid-area: category;
}

.process {
  grid-area: process;
}

.definition {
  display: flex;
  margin-bottom: 25px;
}

.probability {
  grid-area: probability;
  margin-top: 3rem;
  padding-right: 1rem;
}

.consequence {
  grid-area: consequence;
  margin-top: 3rem;
  padding-right: 1rem;
}

.definitionContainer {
  display: flex;
  flex-direction: column;
  margin-left: 130px;
}

.definitionRow {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
}
.definitionDescriptionText {
  font-size: 12px;
  margin-bottom: 10px;
}

.formError {
  height: 20px;
  padding-top: 10px;
  color: var(--color-form-error);
}

.textInputRow {
  margin-bottom: 25px;
}

.textInputRow label {
  width: 80px;
}

.textInputRow input {
  width: 340px;
  border: none;
  border-bottom: thin solid var(--color-text-input);
  color: #777;
  margin: 2px;
  margin-left: 65px;
  padding-left: 3px;
  font-size: 13px;
}

.textInputRow input:focus {
  outline: none;
  color: var(--color-text-input-focus);
  border-bottom: thin solid var(--color-text-input-focus);
}

.textBox {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}

.textBox label {
  width: 135px;
  padding-top: 8px;
}

.textBox textarea {
  font-family: Arial;
  font-size: 13px;
  outline: none;
  border: 2px dashed var(--color-text-box);
  border-radius: 5px;
  padding: 0.5rem;
  min-width: 325px;
  min-height: 80px;
  line-height: 20px;
}

.textBox textarea:focus {
  border: 2px dashed var(--color-text-input-focus);
}

.smallCoso {
  width: 170px;
  position: absolute;
  top: 160px;
  left: 650px;
}
.largeCoso {
  width: 170px;
  position: absolute;
  top: 160px;
  left: 620px;
}
