.MobileAppContainer {
  position: fixed;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
  height: 100vh;
  width: 100vw;
  background-color: rgb(80, 80, 80);
  color: white;
  font-weight: 350;
  font-family: "Montserrat", sans-serif;
  z-index: 900;
}

.logoTitle {
  width: 100px;
  margin-left: 16px;
  margin-right: 16px;
  font-size: 14px;
}
.kommunresurs {
  color: rgb(117, 151, 197);
  font-size: max(3vw, 20px);
}
.zooma {
  margin-top: 20px;
  color: white;
  font-size: max(1vw, 11px);
}

.internkontroll {
  font-size: max(5vw, 28px);
}

/* @import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap"); */
