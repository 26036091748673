.processChart {
  height: 600px;
}

.processChartTitle {
  font-size: 16px;
}

.stepList {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: .5rem;
  overflow-y: scroll;
}

.stepList::-webkit-scrollbar {
  --scrollbar-border: rgb(200, 200, 200, 0.7);
  width: 16px;
  border: thin solid var(--scrollbar-border);
  border-radius: 10px;
}

.stepList::-webkit-scrollbar-thumb {
  --scrollbar-thumb: gray;
  background-color: var(--scrollbar-thumb);
  /* color: transparent; */
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;  
}

.stepItem {
  margin-bottom: .8rem;
}

.stepTitle {
  margin-bottom: .8rem;
  font-size: 14px;
  font-weight: bold;
}

.stepInfo {
  width: 700px;
  font-size: 13px;
  white-space: pre-wrap;
}


/* .processChart */

.processChartSvgImage {
  margin-top: 10px;
  cursor: pointer;
  width: 600px;
  height: auto;
  margin-bottom: 2rem;
  margin-left: 70px;
}
