.verifyChecksContainer {
  font-size: 14px;
  max-height: 180px;
  width: 370px;
  overflow: hidden;
  overflow-y: scroll;
}
.addVerifyCheckButton {
  font-size: 15px;
  width: 180px;
  color: white;
  border: 1px solid rgb(200, 200, 200, 0.7);
  border-radius: 15px;
  background-color: rgb(23, 80, 155);
  height: 29px;
  border-radius: 20px;
  cursor: pointer;
}
.addVerifyCheckButton:hover {
  background-color: rgb(23, 80, 155, 0.85);
}
.addVerifyCheckButton:active {
  outline-width: 0px;
}
.addVerifyCheckButton:focus {
  outline-width: 0px;
}
.cancelVerifyCheckButton {
  font-size: 15px;
  width: 180px;
  color: white;
  border: 1px solid rgb(200, 200, 200, 0.7);
  border-radius: 15px;
  background-color: rgb(50, 50, 50);
  height: 29px;
  border-radius: 20px;
  cursor: pointer;
}
.cancelVerifyCheckButton:hover {
  background-color: rgb(60,60,60);
}
.cancelVerifyCheckButton:active {
  outline-width: 0px;
}
.cancelVerifyCheckButton:focus {
  outline-width: 0px;
}
.commentVerifyCheck {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  padding: 15px 20px;
  width: 400px;
  height: 320px;
  color: black;
  padding-bottom: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.commentVerifyCheck h4 {
  margin-top: 2px;
  margin-bottom: 15px;
}
.fieldRow {
  margin-bottom: 15px;
  outline-width: 0px;
}
.textArea {
  margin-top: 5px;
  margin-bottom: 15px;
  outline-width: 0px;
  height: 95px;
  resize: none;
  font-family: Arial;
  padding: 5px;
}
.descriptionInput {
  font-family: Arial;
  resize: none;
  margin-top: 10px;
  height: 95px;
  line-height: 20px;
}
.verifyListRow {
  display: flex;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.verifyListRow:hover {
  background-color: rgb(61, 68, 75);
}
.verifyListRow > div {
  text-overflow: ellipsis;
  margin-right: 20px;
}
.datePicker {
  outline-width: 0px;
  margin-top: 6px;
}
.checkComment {
  overflow: hidden;
}
.buttonsContainer {
  display: flex;
  justify-content: space-around;
}

#styleScroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: white;
  background-color: rgb(61, 68, 75);
}
#styleScroller::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}
#styleScroller::-webkit-scrollbar-thumb {
  background-color: rgba(2, 76, 214, 0.924);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.verifyCheckHeaderMenu {
  display: flex;
  justify-content: space-between;
}
.verifyCheckSubHeaderMenu {
  cursor: pointer;
  display: flex;
}
.boxCommentVerifyCheck {
  font-family: Arial;
  font-size: 14px;
  font-weight: 300;
  border-radius: 5px;
  border: none;
  outline-width: 0px;
  line-height: 16px;
  margin-bottom: 10px;
  resize: none;
  height: 95px;
  max-height: 95px;
  }
  