.plotterChart {
    width: 580px;
    min-width: 580px;
    height: 280px;
    margin-right: 35px;
    cursor: pointer;
    margin-top: 10px;
    background-color: rgb(246, 246, 246);
    margin-bottom: 30px;
    padding: 20px 20px 20px 30px;
    border: rgb(173, 173, 173) solid 0.1rem;
    border-radius: 15px;
    box-shadow: rgb(158, 158, 158) 0.5px 2px;
}

