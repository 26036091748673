.mainContainer {
    width: 1010px;
    background-color: rgb(246, 246, 246);
    margin-bottom: 50px;
    padding: 20px;
    padding-left: 30px;
    border: rgb(173, 173, 173) solid 0.1rem;
    border-radius: 15px;
    box-shadow: rgb(158, 158, 158) 0.5px 2px;
}
.ContainerTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
}

.riskDataContainer {
    background-color: rgb(236, 236, 236);
    margin-bottom: 25px;
    border: 0.5px solid rgb(173, 173, 173);
    border-radius: 11px;
    padding: 10px;
    margin-right: 10px;
    box-shadow: rgb(158, 158, 158) 0.2px 0.5px;
}
.riskNameRow {
    text-underline-position: under;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    padding-left: 10px;
     margin-top: 10px;
     margin-bottom: 10px;
}
.reviewNameRow {
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 40px;
    margin-top: 5px;
}
.suggestionNameRow {
    font-weight: 400;
    margin-bottom: 4px;
}
.suggestionNameRow2 {
    font-weight: 400;
    padding-left: 10px;
}

.riskOption {
    margin-left: 40px;
}

.riskOption  i {
    cursor: pointer;
}

.riskOption label {
  margin-right: 10px;
}

.riskSelections {
    margin-top: -20px;
    margin-bottom: 20px;
    display: flex;
}
