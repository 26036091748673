.ChooseOrgButton {
  position: relative;
  top: -15px;
}
.headerButtonPanel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
}
.selectContainer {
  margin: 0px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
}
.selectOrganisation {
  display: absolute;
  background: white;
  color: black;
  width: 200px;
  border-radius: 11px;
  font-size: 10px;
  height: 17px;
  padding-left: 7px;
}
.selectOrganisation:disabled {
  background: white;
  color: black;
}
.selectOrganisation:active {
  outline-width: 0px;
  position: relative;
}
.selectOrganisation:focus {
  outline-width: 0px;
}