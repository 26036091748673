/* Small Four x Four diagram */

.diagramContainerSmall {
  margin-top: 15px;
}
.flexContainerSmall {
  position: relative;
  top: -215px;
  left: 220px;
  margin: 0;
  padding: 1px;
  width: 82px;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: nowrap;
  -webkit-flex-flow: row;
  justify-content: space-around;
  line-height: 0px;
}

.flexItemSmall {
  background: tomato;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  flex: 1 0 auto;
  height: 25px;
  width: 25px;
}

.flexItemSmall:before {
  content: "";
  float: left;
  padding-top: 100%;
}

.markerIconSmall {
  position: relative;
  left: -5px;
  top: 12px;
  width: 1px;
  height: 1px;
  line-height: 0px;
  font-size: 1em;
  color: black;
  padding: 1px;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Medium Four x Four diagram */

.diagramContainerMedium {
  margin-top: 15px;
}
.flexContainerMedium {
  position: relative;
  top: -215px;
  left: 220px;
  margin: 0;
  padding: 0px;
  width: 80px;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: nowrap;
  -webkit-flex-flow: row;
  justify-content: space-around;
  line-height: 0px;
}

.flexItemMedium {
  background: tomato;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  flex: 1 0 auto;
  height: 19px;
  width: 19px;
}

.flexItemMedium:before {
  content: "";
  float: left;
  padding-top: 100%;
}

.markerIconMedium {
  position: relative;
  left: -4px;
  top: 8px;
  width: 1px;
  height: 1px;
  line-height: 0px;
  font-size: 0.8em;
  color: black;
  padding: 1px;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Large Five x Five diagram */

.diagramContainerLarge {
  margin-top: 15px;
}
.flexContainerLarge {
  position: relative;
  top: -230px;
  left: 220px;
  margin: 0;
  padding: 0px;
  width: 100px;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: nowrap;
  -webkit-flex-flow: row;
  justify-content: space-around;
  line-height: 0px;
}

.flexItemLarge {
  background: tomato;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  flex: 1 0 auto;
  height: 19px;
  width: 19px;
}

.flexItemLarge:before {
  content: "";
  float: left;
  padding-top: 100%;
}

.markerIconLarge {
  position: relative;
  left: -3px;
  top: 8px;
  width: 1px;
  height: 1px;
  line-height: 0px;
  font-size: 0.8em;
  color: black;
  padding: 1px;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* General Colours */
.greenDarkColor {
  background-color: rgb(84, 180, 122);
}
.orangeDarkColor {
  background-color: rgb(231, 37, 37);
}
.greenLightColor {
  background-color: rgb(106, 226, 154);
}
.orangeLightColor {
  background-color: rgb(254, 125, 53);
}
.orangeLightColor {
  background-color: rgb(254, 125, 53);
}
.yellowColor {
  background-color: rgb(252, 255, 102);
}
