.mainContainer {
    width: 1030px;
    background-color: rgb(246, 246, 246);
    margin-top: 10px;
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 30px;
    border: rgb(173, 173, 173) solid 0.1rem;
    border-radius: 15px;
    box-shadow: rgb(158, 158, 158) 0.5px 2px;
}
.ContainerTitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}
.riskRow {
    display: flex;
    margin-bottom: 5px;
    font-size: 13px;
}
.largeColumn {
    width: 200px;
}
.largeColumnRiskName {
    width: 200px;
    text-decoration: underline;
    cursor: pointer;
    text-underline-position: under;
}
.smallColumn {
    align-items: center;
    text-align: center;
    width: 50px;
}
.riskHeader {
    font-weight: 600;
    margin-bottom: 5px;
}