.createPortfolioContainer {
  margin-top: 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
}
.createPortfolioItem {
  margin-top: 5px;
}
.createPortfolioItem:active {
  outline-width: 0px;
  position: relative;
}
.createPortfolioItem:focus {
  outline-width: 0px;
}
.createPortfolioSubmitButton:active {
  outline-width: 0px;
  position: relative;
}
.createPortfolioSubmitButton:focus {
  outline-width: 0px;
}
.selectBox {
  font-size: 12px;
  min-width: 250px;
  margin-bottom: 15px;
  padding: 3px;
  cursor: pointer;
  border: thin solid var(--color-select-box);
  border-radius: 5px;
}
.selectBox, select:focus {
  outline: none;
}
.dateFromPicker, input {
  border-radius: 5px;
  border: 1px solid #bbb;
  padding: 2px;
}
.dateFromPicker, input :focus {
  outline: none;
}