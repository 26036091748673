.diagramContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 1200px;
    min-width: 1200px;
}
.diagramContainer > * {
    flex: 500px 500px ;
}
.selectBox {
    margin-top: 40px;
  display: flex;
}
.selectBox label {
  padding-top: 3px;
  font-size: 18px;
}
.selectBox select {
  height: 26px;
  min-width: 250px;
  margin-left: 5px;
  margin-bottom: 55px;
  padding: 3px;
  cursor: pointer;
  border: thin solid var(--color-select-box);
  border-radius: 5px;
}
.selectBox select:focus {
  outline: none;
}
.riskOption {
    margin-left: 40px;
}
.riskOption  i {
    cursor: pointer;
}
.riskOption label {
  margin-right: 10px;
}
