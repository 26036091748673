.radioContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  font-size: 14px;
}
.radioContainer input[type="radio"] {
  display: none;
}

.radioContainer label::before {
  /* content: "";
  display: inline-block;
  position: relative;
  top: 5px;
  border-radius: 50%; */
  background-color: white;
  top: 5px;
  content: "";
  display: inline-block;
  border: 1px solid #000;
  border-radius: 50%;
  position: relative;
  margin-right: 7px;
  top: 3px;
}

.radioContainer input[type="radio"]:checked + label::before {
  background-color: #4077c4;
}
.radioContainer input[type="radio"]:checked + label {
  box-sizing: border-box;
}


.radioButtonStyle + label::before {
  width: 13px;
  height: 13px;
  margin-right: 10px;
  cursor: pointer;
  
}
.generalRadioButton {
  margin-left:30px;
}
/* .greenRadio input[type="radio"]+ label::before {
  margin-right: 10px;
  cursor: pointer;
}
.redRadio input[type="radio"]+ label::before {
  margin-right: 10px;
  cursor: pointer;
} */
.riskOption {
  margin-bottom: 35px;
}
.riskOption  i {
  cursor: pointer;
  position: relative;
  top: 1px;
}
.riskOption label {
margin-right: 10px;
}