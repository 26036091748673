.groupContainer {
  width: 900px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.groupContainer > div {
  /* background-color: rgb(126, 165, 216, 0.99); */
  margin: 0px 18px 28px 0px;
  border-radius: 12px;
  padding: 10px;
  padding-left: 11px;
  min-width: 170px;
  order: 2;
  color: white;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.riskGroupTitle {
  font-size: 15px;
  margin-bottom: 8px;
}
.riskGroupInfoText {
font-size: 13px;
}