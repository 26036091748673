.formContainer {
  width: 600px;
  margin-bottom: 2rem;
  margin-top: 3.5rem;
}
.textInputRow {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
}

.textInputRow > input {
  width: 320px;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #333;
  margin-right: 14px;
  padding-left: 3px;
  font-size: 13px;
}

.textInputRow > input:focus {
  outline-width: 0px;
  color: #333;
  font-size: 13px;
}

.textInputRow > label {
  width: 135px;
  font-size: var(--labelText);
}

/* For checkbox */
.superCheckBox {
  padding-top: 6px;
  padding-bottom: 0px;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.superCheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 8px;
  left: 136px;
  height: 18px;
  width: 18px;
  background-color: rgb(250, 250, 250);
  border: 1px #ccc solid;
  border-radius: 3px;
}

.superCheckBox:hover input ~ .checkmark {
  background-color: rgb(244, 244, 244);
}

.superCheckBox input:checked ~ .checkmark {
  background-color: rgb(47, 100, 171, 0.85);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.superCheckBox input:checked ~ .checkmark:after {
  display: block;
}

.superCheckBox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(39deg);
  -ms-transform: rotate(39deg);
  transform: rotate(39deg);
}
.createPortfolioItem {
  margin-top: 5px;
  width: 400px;
  min-height: 120px;
  line-height: 20px;
  font-family: Arial;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #bbb;
  resize: none; 
}
.createPortfolioItem:active {
  outline-width: 0px;
}
.createPortfolioItem:focus {
  outline-width: 0px;
}
