.attestRow {
    display: flex;
}
.nameColumn {
    width: 255px;
    padding-top: 9px;
}
.iconColumn {
    width: 60px;
    padding-top: 9px;
}
.iconColumn > i {
    color: #888;
}

.normalColumn {
    width: 105px;
    padding-top: 9px;
    
}
.normalColumn > i {
    margin-left: 30px;
    color: #888;
}
.normalColumn > i:hover {
    cursor: pointer;
}
.managersColumn {
    width: 455px;
    padding-top: 9px;
}
.titleName {
    font-weight: 650;
    margin-bottom: 10px;
    text-underline-position: under;
    text-decoration: underline;
}