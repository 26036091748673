.toggleSideBar {
  width: 19px;
  height: 19px;
  background-color: #f1f1f1;
  border-radius: 50%;
  padding-left: 8px;
  padding-top: 7px;
  position: absolute;
  right: 30px;
  top: 70px;
  cursor: pointer;
}

.sidenav {
  font-size: 12px;
  height: 100vh;
  width: 270px;
  position: fixed;
  top: 0;
  background-color: rgb(80, 80, 80);
  overflow: hidden;
  padding-top: 125px;
  transition: all 0.3s ease-in-out;
}

.sidebar-item {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  list-style: none;
  text-align: left;
  margin-left: 0px;
  padding-left: 20px;
  margin-right: 30px;
  position: relative;
  top: 10px;
}

.sidebar-item > li {
  padding: 0.4rem 0.5rem;
}

.sidebar-item > li a {
  border-radius: 20px;
  text-decoration: none;
  padding: 0.3rem;
  transition: all 0.3s ease-in-out;
  background-color: #f1f1f1;
  color: #333;
}

.sideBarShow > li a:hover {
  padding-left: 1.3rem;
  color: #000;
  background-color: white;
}

.sideBarHide > li a:hover {
  color: #000;
  background-color: white;
  transform: scale(1.2);
}

.sidebar-icon {
  padding-left: 3px;
  padding-right: 10px;
}

.sideBarShow {
  width: 220px;
}
.sideBarHide p {
  display: none;
}

.sideBarShow p {
  display: inline;
}

.main-no-sidebar {
  width: 95px;
}

.sidenav a {
  text-decoration: none;
  color: #818181;
  display: block;
}

.side-bar-logo {
  width: 7rem;
  margin-left: 5.8rem;
  position: absolute;
  z-index: 1;
  top: 630px;
  background-color: rgb(223, 223, 223);
  border-radius: 10%;
  border: 1px solid white;
  transition: all 0.3s ease-in-out;
}
.hideLogo {
  transform: scale(0);
  margin-left: 1rem;
}

.quarter-circle-top-left {
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 15px 0 0 0;
  -moz-border-radius: 15px 0 0 0;
  -webkit-border-radius: 15px 0 0 0;
}
.t1 {
  background-color: rgb(80, 80, 80);
  width: 15px;
  height: 70px;
}
.t2 {
  background-color: rgb(80, 80, 80);
  width: 15px;
  height: 15px;
}
.t3 {
  background-color: white;
  width: 15px;
  z-index: 1;
  height: 305px;
}
.circleStrip {
  width: 15px;
  height: 100vh;
  background-color: white;
  padding: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.welcome-container {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  margin-left: 30px;
  width: 200px;
  height: 130px;
  color: white;
}
.welcome-container-hide {
  transform: scale(0.2);
  margin-left: 0px;
}
.welcome-container-icon {
  margin-left: 10px;
  font-size: 40px;
  padding-bottom: 20px;
}

.welcome-container-hr {
  border: 1px solid white;
  border-radius: 50%;
  width: 180px;
}
.welcome-container-greeting {
  margin-bottom: 3px;
}

.side-bar-footer {
  width: 14rem;
  margin-left: 1rem;
  position: absolute;
  z-index: 1;
  top: 610px;
  font-size: 12px;
  text-align: center;
  color: var(--sidebar-footer-fg);
  transition: all 0.3s ease-in-out;
 }
  
 .hide-footer {
  transform: scale(0);
  margin-left: 1rem;
 }