.cardContainer {
  width: 700px;
  color: black;
  border: 5px solid rgb(20, 106, 114);
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
  margin-top: 0px;
}
.header {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

.subHeader {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}

.infoContainer {
  font-size: 14px;
  font-weight: 600;
  padding: 20px;
  top: 15px;
  left: 20px;
  max-width: 660px;
  width: 660px;
}

.descriptionContainer {
  padding-bottom: 15px ;
  padding-left: 20px ;
  font-size: 14px;
  font-weight: 400;
  max-width: 660px;
  width: 660px;
}

.main {
  display: flex;
  max-width: 330px;
  width: 330px;
}
.item {
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  min-width: 310px;
  min-width: 310px;
}

.textBox {
  cursor: pointer;
  color: black;
  font-family: Arial;
  font-size: 13px;
  font-weight: 300;
  border-radius: 5px;
  border-color: rgb(200, 200, 200);
  border: none;
  /* background: rgb(20, 106, 114); */
  outline-width: 0px;
  width: 646px;
  line-height: 16px;
  margin-top: 4px;
  resize: none;
  min-height: 40px;
  max-height: 300px;
}
.textBox:focus {
  padding-left: 5px;
  cursor: text;
  background-color: white;
  color: black;
}

.textBox::placeholder { 
  color: gray;
}
