:root {

  --fade-loading-modal-time: 3s;
}

.fadeWaiting {
  display: none;
}

.fadeIn {
  animation: fadeIn ease var(--fade-loading-modal-time);
  -webkit-animation: fadeIn ease var(--fade-loading-modal-time);
  -moz-animation: fadeIn ease var(--fade-loading-modal-time);
  -o-animation: fadeIn ease var(--fade-loading-modal-time);
  -ms-animation: fadeIn ease var(--fade-loading-modal-time);
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}
