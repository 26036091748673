.logoutBarContainer {
  position: absolute;
  font-size:  calc(0.3vw + 10px);
  right: 10px;
  display: flex;
  flex-direction: row;
  width: 360px;
  justify-content: flex-end;
}

i.logoutBarIcons {
  font-size:  calc(0.3vw + 12px);
  cursor: pointer;
}
.logoutBarLabels {
  position: relative;
  padding-right: 25px;
  
  cursor: pointer;
}
