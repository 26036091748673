.cardContainer {
  width: 700px;
  color: black;
  border: 5px solid rgb(20, 106, 114);
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
  margin-top: 0px;
}
.header {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
}
.subHeader {
  font-size: 14px;
  margin-top: 5px;
  font-weight: 600;
}
.infoContainer {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
  top: 15px;
  left: 20px;
  max-width: 660px;
  width: 660px;
}
.descriptionContainer {
  padding-bottom: 35px ;
  padding-left: 20px ;
  font-size: 14px;
  font-weight: 400;
  max-width: 660px;
  width: 660px;
}
.textBox {
  cursor: pointer;
  font-family: Arial;
  font-size: 13px;
  font-weight: 300;
  border-radius: 5px;
  border-color: rgb(200, 200, 200);
  border: none;
  /* background: rgb(20, 106, 114); */
  outline-width: 0px;
  width: 646px;
  line-height: 16px;
  margin-top: 4px;
  resize: none;
  min-height: 40px;
  max-height: 300px;
}
.textBox:focus {
  padding-left: 5px;
  cursor: text;
  /* background-color: white; */
  color: black;
}
.textBox::placeholder { 
  /* color: white; */
  color: black;
}

.radioContainer {
  display: flex;
  flex-direction: row;
}
.radioContainer input[type="radio"] {
  display: none;
}
.radioContainer label::before {
  content: "";
  display: inline-block;
  position: relative;
  top: 5px;
  border-radius: 50%;
}
.radioContainer input[type="radio"]:checked + label::before {
  box-sizing: border-box;
  border: 3px solid #000;
}
.radioContainer input[type="radio"]:checked + label {
  box-sizing: border-box;
}
.radioButtonStyle + label::before {
  width: 20px;
  height: 20px;
}
.generalRadioButton {
  margin-left: 40px;
}
.whiteRadio input[type="radio"]+ label::before {
    border-inline-color: black;
    border-inline-style: solid;
    border-inline-width: 1px;
}
.whiteRadio input[type="radio"]+ label::before {
  margin-right: 10px;
  cursor: pointer;
  background-color: white;
}
.greenRadio input[type="radio"]+ label::before {
  margin-right: 10px;
  background-color: rgb(84, 180, 122);
  cursor: pointer;
}
.yellowRadio input[type="radio"]+ label::before {
  margin-right: 10px;
  background-color: rgb(252, 255, 102);
  cursor: pointer;
}
.redRadio input[type="radio"]+ label::before {
  margin-right: 10px;
  background-color: rgb(231, 37, 37);
  cursor: pointer;
}