:root {
  --color-description: rgb(14, 14, 14);
}
.listRisksContainer {
  margin-top: 40px;
  margin-bottom: 30px;
}
.listRisksContainer label {
  font-size: var(--labelText);
}
.filterContainer {
  width: 1000px;
  min-width: 1200px;
}
.riskRow {
  display: flex;
  flex-direction: row;
  min-width: 1215px;
  max-width: 1215px;
  margin-bottom: 4px;
  padding-top: 5px;
  box-sizing: border-box;
  outline: 0px;
  border-bottom: 1px dashed black;
  padding-bottom: 6px;
}
.riskRowIconItem {
  text-align: center;
  color: rgb(216, 216, 216);
  font-size: 14px;
  cursor: pointer;
  width: 50px;
  min-width: 50px;
  
}
.riskRowIconItemTitle {
  text-align: center;
  color: var(--color-description);
  width: 50px;
  min-width: 50px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.riskRowTitleItem {
  width: 200px;
  min-width: 200px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.riskRowTitleItemDescription {
  width: 260px;
  min-width: 260px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.riskRowTitleItemName {
  width: 240px;
  min-width: 240px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.riskRowTitleItemDate {
  width: 110px;
  min-width: 110px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.riskRowTitleItemSmall {
  text-align: center;
  width: 70px;
  min-width: 70px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.riskRowItem {
  width: 200px;
  min-width: 200px;
  white-space: nowrap;
  color: var(--color-description);
  font-size: 14px;
}
.riskRowItemDescription {
  width: 260px;
  min-width: 260px;
  white-space: nowrap;
  color: var(--color-description);
  font-size: 14px;
}
.riskRowItemDate {
  width: 110px;
  min-width: 110px;
  color: var(--color-description);
  font-size: 14px;
}
.riskRowItemLink {
  width: 240px;
  min-width: 240px;
  white-space: nowrap;
  color: var(--color-description);
  font-size: 14px;
}
.riskRowItemLink:hover {
  cursor: pointer;
}
.riskRowItemSmall {
  text-align: center;
  display: inline-flex;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  color: var(--color-description);
  font-size: 14px;
}
.searchInputRow {
  margin-bottom: 15px;
  font-size: 13px;
}
.searchInputRow input {
  width: 200px;
  border: none;
  border-bottom: thin solid var(--color-text-input);
  color: rgb(56, 56, 56);
  margin: 2px;
  margin-left: 17px;
  padding-left: 3px;
  font-weight: 100;
  font-size: 13px;
}
.searchInputRow input:focus {
  outline: none;
  color: var(--color-text-input-focus);
  border-bottom: thin solid var(--color-text-input-focus);
}
.riskOption {
  margin-left: 50px;
}
.riskOption label {
  margin-right: 10px;
}
