.filterTextBox {
  margin: 0px;
  margin-left: 18px;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
}
.filterContainer {
  /* background: #89b0e7; */
  background: rgb(233, 239, 247);
  width: 1100px;
  height: 68px;
  border-radius: 14px;
  padding-top: 6px;
  margin-bottom: 32px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.selectBox {
float: left;
}
.selectBox label {
  padding-top: 3px;
  font-size: var(--labelText);
}
.selectBox select {
  font-size: 12px;
  min-width: 240px;
  margin-left: 15px;
  margin-bottom: 30px;
  padding: 3px;
  cursor: pointer;
  border: thin solid var(--color-select-box);
  border-radius: 5px;
}
.selectBox select:focus {
  outline: none;
}
.rvLabel {
  margin-left: 10px;
}

.calendarOnly {
  float: left;
  margin-left: 50px;
  margin-right: 20px;
}
.calendarOnly :hover {
  cursor: pointer;
}
.ikpOnly {
  cursor: pointer;
  float: left;
  margin-top: 2px;
  font-size: 13px;
  font-weight: 600;
  margin-left: 15px;
  margin-right: 50px;
}
.waitingIcpOnly {
  float: left;
  color: rgb(233, 239, 247);
  margin-left: 120px;
}