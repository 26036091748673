:root {
  --color-description: rgb(14, 14, 14);
  --color-row-background: rgb(255, 255, 255);
}
.listRisksContainer {
  margin-top: 40px;
  margin-bottom: 30px;
}
.listRisksContainer label {
  font-size: var(--labelText);
}
.riskRow {
  display: flex;
  flex-direction: row;
  background-color: var(--color-row-background);
  min-width: 1200px;
  max-width: 1500px;
  margin-bottom: 4px;
  padding-top: 5px;
  box-sizing: border-box;
  outline: 0px;
  padding-bottom: 6px;
}
.riskRow:last-child {
  border-bottom: 1px dashed black;
}
.riskRowIconItem {
  text-align: center;
  color: var(--color-description);
  font-size: 14px;
  cursor: pointer;
}
.riskRowTitleItem {
  width: 270px;
  min-width: 200px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.riskRowTitleItemName {
  width: 300px;
  min-width: 220px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.riskRowTitleItemDate {
  width: 110px;
  min-width: 110px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.riskRowTitleItemSmall {
  text-align: center;
  width: 70px;
  min-width: 70px;
  color: var(--color-description);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.riskRowItem {
  width: 270px;
  min-width: 200px;
  white-space: nowrap;
  color: var(--color-description);
  font-size: 14px;
}
.riskRowItemDate {
  width: 110px;
  min-width: 110px;
  color: var(--color-description);
  font-size: 14px;
}

.riskRowItemLink {
  width: 300px;
  min-width: 220px;
  white-space: nowrap;
  color: var(--color-description);
  font-size: 14px;
}
.riskRowItemLink:hover {
  /* text-decoration: underline; */
  cursor: pointer;
}
.riskRowItemSmall {
  text-align: center;
  display: inline-flex;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  color: var(--color-description);
  font-size: 14px;
}

.searchInputRow {
  margin-bottom: 15px;
  font-size: 13px;
}

.searchInputRow input {
  width: 200px;
  border: none;
  border-bottom: thin solid var(--color-text-input);
  color: rgb(56, 56, 56);
  margin: 2px;
  margin-left: 17px;
  padding-left: 3px;
  font-weight: 100;
  font-size: 13px;
}

.searchInputRow input:focus {
  outline: none;
  color: var(--color-text-input-focus);
  border-bottom: thin solid var(--color-text-input-focus);
}

.selectBox {
  display: flex;
}

.selectBox label {
  padding-top: 3px;
  font-size: var(--labelText);
}

.selectBox select {
  font-size: 12px;
  min-width: 250px;
  margin-left: 15px;
  margin-bottom: 30px;
  padding: 3px;
  cursor: pointer;
  border: thin solid var(--color-select-box);
  border-radius: 5px;
}
.selectBox select:focus {
  outline: none;
}
.riskOption {
  margin-left: 50px;
}
.riskOption label {
  margin-right: 10px;
}
.riskFoldDescription  {
  margin-right: 30px;
  margin-bottom: 8px;
  padding: 0px;
}
.foldedTitle {
  font-size: 12px;
  font-weight: 500;
}
.foldedText {
  font-size: 11px;
  font-weight: 400;
}

.statusColourBox {
  width: 9px;
  height: 9px;
  position: absolute;
  left: -20px;
  margin-top: 7px;
  border-radius: 30%;
}
.statusColourBoxFolded {
  width: 9px;
  height: 9px;
  position: absolute;
  left: -20px;
  margin-top: 5px;
  border-radius: 30%;
}