.verifyChecksContainer {
  max-height: 180px;
  width: 370px;
  overflow: hidden;
  font-size: 14px;
  overflow-y: scroll;
}
.addVerifyCheckButton {
  font-size: 15px;
  width: 180px;
  color: white;
  border: 1px solid rgb(200, 200, 200, 0.7);
  border-radius: 15px;
  background-color: rgb(23, 80, 155);
  height: 29px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 20px;
  cursor: pointer;
}
.addVerifyCheckButton:hover {
  background-color: rgb(23, 80, 155, 0.85);
}
.addVerifyCheckButton:active {
  outline-width: 0px;
}
.addVerifyCheckButton:focus {
  outline-width: 0px;
}
.commentVerifyCheck {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  padding: 15px 20px;
  width: 300px;
  color: black;
  text-align: center;
  padding-bottom: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.commentVerifyCheck h4 {
  padding: 2px;
  margin: 2px;
}
.fieldRow {
  margin-bottom: 15px;
  outline-width: 0px;
}
.descriptionInput {
  font-family: Arial;
  margin-top: 10px;
  height: 95px;
  width: 270px;
  resize: none;
  line-height: 20px;
}
.verifyListRow {
  display: flex;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.verifyListRow:hover {
  background-color: rgb(61, 68, 75);
}
.verifyListRow > div {
  text-overflow: ellipsis;
  margin-right: 20px;
}
.barne {
  overflow: hidden;
}

#styleScroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: whitesmoke;
  background-color: rgb(61, 68, 75);
}
#styleScroller::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}
#styleScroller::-webkit-scrollbar-thumb {
  background-color: rgba(2, 76, 214, 0.924);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
