.createPortfolioContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.createPortfolioItem {
  margin-top: 5px;
}
.createPortfolioSubmitButton {
  margin-top: 30px;
  font-size: 15px;
  width: 100px;
  color: white;
  border: none;
  border-radius: 15px;
  background-color: rgb(80, 80, 80, 0.85);
  height: 29px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 20px;
  cursor: pointer;
}
.createPortfolioSubmitButton:hover {
  background-color: rgb(80, 80, 80, 0.95);
}
.createPortfolioItem:active {
  outline-width: 0px;
  position: relative;
}
.createPortfolioItem:focus {
  outline-width: 0px;
}

.createPortfolioSubmitButton:active {
  outline-width: 0px;
  position: relative;
}
.createPortfolioSubmitButton:focus {
  outline-width: 0px;
}
