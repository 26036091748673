.container {
  margin-top: 40px;
  width: 920px;
  padding: 20px;
  padding-bottom: 25px;
  background-color: rgb(246, 246, 246);
  border: rgb(173, 173, 173) solid 0.1rem;
  border-radius: 15px;
  box-shadow: rgb(158, 158, 158) 0.5px 2px;
}
.header {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 4px;
}
.buttonHeader {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 940px;
  flex-wrap: wrap;
}
.buttonContainer {
  position: relative;
  left: 35px;
  top: 0px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 770px;
  flex-wrap: wrap;
}
.icpButton {
  height: 24px;
  border: 0.5px solid #333;
  margin-bottom: 12px;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: rgb(61, 61, 61);
  border-radius: 7px;
  background-color: rgb(199, 199, 199);
  cursor: pointer;
}
.selectedIcpButton {
  color: white;
  background-color: rgb(27, 127, 136);
}
.selectedIcpButton:active {
  outline-width: 0px;
}
.selectedIcpButton:focus {
  outline-width: 0px;
}
.titleRowDiv {
  margin-top: 6px;
  padding-bottom: 5px;
  display: flex;
  border-bottom: 1px solid grey;
  flex-direction: row;
  font-weight: 600;
  font-size: 14px;
}
.rowDiv {
  font-size: 14px;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
}
.colDiv {
  width: 310px;
}
.colDivRiskName {
  width: 350px;
  cursor: pointer;
}
.colDivFrequency {
  width: 200px;
}
.colDivDate {
  width: 220px;
}
.colDivCalendar {
  width: 50px;
}
.riskRowIconItem {
  text-align: end;
  color: red;
  font-size: 13px;
}
