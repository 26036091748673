.mainContainer {
  width: 660px;
  padding: 20px;
  border: 5px solid rgba(78, 123, 184, 1);
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
}

.oneControllerContainer {
  font-size: 12px;
  display: flex;
  color: white;
  padding-top: 6px;
  padding-left: 16px;
  background: rgb(105, 152, 218);
  border-radius: 9px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  height: 23px;
  margin-bottom: 10px;
}

.header {
  margin-left: 4px;
  font-size: 20px;
  color: black;
  margin-bottom: 10px;
  font-weight: 600;
}

.iconBox {
  position: absolute;
  left: 650px;
  cursor: pointer;
}

.controllerColumnZero {
  position: absolute;
  left: 200px;
}
.controllerColumnOne {
  position: absolute;
  left: 360px;
}
.controllerColumnTwo {
  position: absolute;
  left: 480px;
}
.controllerColumnThree {
  position: absolute;
  left: 580px;
}
.controllerColumnFour {
  position: absolute;
  left: 620px;
}

.riskRowIconItem {
  color: red;
  font-size: 12px;
  padding-bottom: 5px;
}