.portfolioContainer {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  background-color: rgb(78, 123, 184);
  width: 730px;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}
.portfolioContainerActive {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  background-color: rgb(27, 127, 136);
  width: 730px;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}
.portfolioContainerArchived {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  background-color: rgb(105, 116, 116);
  width: 730px;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}
.portfolioTextContainer {
  letter-spacing: 1.5px;
  font-weight: 350;
  padding-left: 20px;
  display: flex;
  width: 540px;
  flex-direction: column;
  margin-bottom: 7px;
}
.portfolioIconChild {
  align-self: center;
  color: white;
  margin-left: 20px;
  margin-bottom: 7px;
  font-size: 20px;
  cursor: pointer;
}
.portfolioIconChildColumn {
  align-self: center;
  color: white;
  width: 25px;
  margin-left: 20px;
  margin-bottom: 5px;
  font-size: 20px;
  cursor: pointer;
}
.portfolioHeaderChild {
  margin-bottom: 3px;
  cursor: pointer;
  font-size: 15px;
  color: white;
  font-weight: 600;
}
.portfolioTextChild {
  font-size: 13px;
  display: flex;
  color: white;
  padding-bottom: 1px;
}
.textItem {
  margin-right: 20px;
}
.spinner {
  width: 40px;
  position: relative;
  top: 10px;
  left: 70px;
}
.boxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 750px;
  margin-left: 5px;
}
.waitingBox {
  margin-right: 15px;
  min-width: 25px;
  height: 25px;
  border-radius: 7px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  background-color: rgb(78, 123, 184);
}
.activeBox {
  margin-right: 15px;
  min-width: 25px;
  height: 25px;
  border-radius: 7px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  background-color: rgb(27, 127, 136);
}
