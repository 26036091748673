.mainContainer {
    display: flex;
    flex-wrap: wrap;
}
.boxContainer {
    min-width: 210px;
    min-height: 110px;
    max-width: 210px;
    max-height: 110px;
    border-radius: 10px;
    margin-right: 44px;
    margin-bottom: 30px;
    background-color: rgb(246, 246, 246);
    padding: 10px 10px 4px 10px;
    border: rgb(173, 173, 173) solid 0.1rem;
    border-radius: 15px;
    box-shadow: rgb(158, 158, 158) 0.5px 2px;
}
.firstBoxRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50%;
}
.firstBoxRowFirstColumn {
    width: 50%;
    height: 100%;
    margin-left: 20px ;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 19px;
}
.firstBoxRowFirstColumn:first-child {
    font-size: 17px;
    font-weight: 600;
    word-spacing: 100vw;
}
.firstBoxRowSecondColumn {
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}
.secondBoxRow {
    margin-left: 20px;
    margin-top: 7px;
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    height: 50%;
    line-height: 18px;
}
.secondBoxRowCentered {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
}
.firstBoxWholeRow {
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}