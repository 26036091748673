/* Custom properties */
:root {
    --tooltip-text-color: white;
    --tooltip-background-color: #333;
    /* --tooltip-background-color: black; */
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
  }
  
  /* Wrapping */
  .TooltipWrapper {
    display: inline-block;
    position: relative;
  }
  
  /* Absolute positioning */
  .TooltipTip {
    position: absolute;
    border-radius: 4px;
    /* border: 1px solid white; */
    left: 50%;
    transform: translateX(-50%);
    padding: 12px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1.3;
    z-index: 100;
    white-space: pre-wrap;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  /* CSS border triangles */
  .TooltipTip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }
  
  /* Absolute positioning */
  .TooltipTip.top {
    top: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .TooltipTip.top::before {
    top: 100%;
    border-top-color: var(--tooltip-background-color);
  }
  
  /* Absolute positioning */
  .TooltipTip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .TooltipTip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
  }
  
  /* Absolute positioning */
  .TooltipTip.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .TooltipTip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
  }
  
  /* Absolute positioning */
  .TooltipTip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .TooltipTip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
  }
  