.cardContainer {
  width: 700px;
  color: black;
  border: 5px solid rgba(78, 123, 184, 1);
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
}

.cardContainerIcp {
    width: 700px;
    color: black;
    border: 5px solid rgb(20, 106, 114);
    border-radius: 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    margin-bottom: 40px;
}

.header {
  padding-bottom: 30px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}

.subHeader {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: 600;
}

.infoContainer {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 20px;
  max-width: 660px;
  width: 660px;
}

.descriptionContainer {
  padding-left: 20px;
  font-size: 13px;
  font-weight: 400;
  max-width: 660px;
  width: 660px;
}

.hiddenContainer {
  display: flex;
  max-width: 330px;
  width: 330px;
}
.item {
  padding: 20px;
  font-size: 13px;
  font-weight: 400;
  min-width: 310px;
  min-width: 310px;
}

.smallCoso {
  width: 170px;
  position: relative;
  margin-bottom: -100px;
  top: -130px;
  left: 530px;
}
.largeCoso {
  width: 170px;
  position: relative;
  margin-bottom: -100px;
  top: -135px;
  left: 530px;
}
.appendix {
  padding: 20px;
  padding-bottom: 40px;
  font-size: 13px;
  font-weight: 400;
}
[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"] + label {
  color: white;
  cursor: pointer;
}
.listedFile {
  text-decoration: underline;
  cursor: pointer;
}
.listedFile:hover {
  text-decoration: underline;
  /* color: rgb(204, 208, 224); */
  color: gray;
}
.AddUploadButton {
  font-weight: 600;
  margin-top: 40px;
}
