.controllerContainer {
  transition: all 0.3s ease-in-out;
  position: sticky;
  margin-bottom: 40px;
  top: 253px;
  display: flex;
  margin-left: 40px;
  flex-direction: wrap;
  background-color: rgb(80, 80, 80);
  border-radius: 10px;
  height: 480px;
  z-index: 5;
  color: white;
  padding-left: 20px;
  width: 400px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.descriptionInput {
  margin-top: 3px;
  font-family: Arial;
  height: 95px;
  width: 370px;
  resize: none;
  line-height: 20px;
  border-radius: 5px;
  padding-left: 4px;
}

.fieldRow {
  margin-bottom: 15px;
  font-size: 12px;
  min-width: 100px;
  border-radius: 5px;
}
.nameInput {
  margin-bottom: 15px;
  font-size: 12px;
  width: 200px;
  border-radius: 3px;
  border: none;
  padding: 3px;
}
.buttonPlacer {
  margin-top: 12px;
  position: absolute;
  left: 305px;
}
.iconBox {
  position: absolute;
  top: 20px;
  left: 380px;
  cursor: pointer;
}
.addControllerHeader {
  margin: 15px 0px 16px 0px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;

}
.addControllerLabel {
  font-size: 14px;
  font-weight: 400;
}