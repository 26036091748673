.topBarContainer {
  position: fixed;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
  background-color: rgb(80, 80, 80);
  color: white;
  font-size:  calc(0.5vw + 11px);
  font-weight: 350;
  font-family: "Montserrat", sans-serif;
  z-index: 100;
}

.logoTitle {
  width: 4rem;
  margin-left: 16px;
  margin-right: 16px;
}
.copeLogoTitle {
  width: 2.4rem;
  margin-left: 16px;
  margin-right: 16px;
}

.rightTitle {
  color: rgb(117, 151, 197);
}

