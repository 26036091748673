.inputHeader {
  color: white;
  margin-top: 3px;
  font-size: 14px;
}
.devReportEditorContainer {
  display: flex;
  flex-direction: column;
  background-color: rgb(39, 94, 167);
  border-radius: 15px;
  padding: 15px 25px;
  width: 480px;
  height: 510px;
  color: black;
  padding-bottom: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.devReportEditorHeader {
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  padding: 10px 0px;
  width: 480px;
  font-size: 19px;
  color: white;
  font-weight: 350;
}
.descriptionInput {
  font-family: Arial;
  margin-top: 10px;
  padding-left: 5px;
  border: none;
  background-color: white;
  border-radius: 5px;
  height: 125px;
  width: 470px;
  resize: none;
  line-height: 20px;
}
.updateDeviationReportSubmitButton {
  margin: 20px;
  font-size: 13px;
  width: 180px;
  color: black;
  border: 1px solid rgba(83, 83, 83, 0.7);
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.85);
  height: 25px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 20px;
  cursor: pointer;
}
.updateDeviationReportSubmitButton:hover {
  background-color: rgba(255, 255, 255, 0.95);
}
.updateDeviationReportSubmitButton:active {
  outline-width: 0px;
}
.updateDeviationReportSubmitButton:focus {
  outline-width: 0px;
}
.fieldRow {
  margin-bottom: 25px;
  outline-width: 0px;
}

#styleScroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: white;
  background-color: #fff;
}

#styleScroller::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

#styleScroller::-webkit-scrollbar-thumb {
  background-color: rgba(2, 76, 214, 0.924);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.topEditorBar {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 300px;
}