.container {
  margin-top: 40px;
  width: 920px;
  padding: 20px;
  padding-bottom: 25px;
  background-color: rgb(246, 246, 246);
  border: rgb(173, 173, 173) solid 0.1rem;
  border-radius: 15px;
  box-shadow: rgb(158, 158, 158) 0.5px 2px;
}
.header {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 4px;
}
.titleRowDiv {
  margin-top: 6px;
  padding-bottom: 5px;
  display: flex;
  border-bottom: 1px solid grey;
  flex-direction: row;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}
.rowDiv {
  font-size: 14px;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
}
.colDiv {
  width: 310px;
}
.colDivRiskName {
  width: 350px;
  cursor: pointer;
}
.colDivDate {
  width: 220px;
}
.colDivCalendar {
  width: 50px;
}
.attestButton {
  color: white;
  height: 20px;
  border: 0.5px solid #333;
  margin-bottom: 2px;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.2px;
  border-radius: 7px;
  background-color: rgb(199, 199, 199);
  cursor: pointer;
}