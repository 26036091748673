.listUsersTable {
  padding-bottom: 30px;
}

.listUsersTable > tr,
th {
  text-align: left;
  height: 26px;
  font-size: 14px;
}

.centerColumnClass {
  padding-left: 20px;
  text-align: center;
}

.linkEditUserName {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  text-underline-position: under;
}
.titleName {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  text-underline-position: under;
  margin-bottom: 1px;
}

.textInputRow {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
}

.textInputRow > input {
  width: 238px;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #333;
  margin: 2px;
  padding-left: 4px;
  padding-bottom: 4px;
  font-size: 13px;
}

.textInputRow > input:focus {
  outline-width: 0px;
  color: #333;
  font-size: 13px;
}

.textInputRow > label {
  width: 150px;
  font-size: var(--labelText);
}
