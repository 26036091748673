.cosoMenuContainer {
  width: 400px;
  display: flex;
  flex-direction: row;
  padding-bottom: 32px;
}
.cosoDiagramContainer {
  position: absolute;
  left: 600px;
}
.scaleButton {
  cursor: pointer;
  font-size: 12px;
  font-weight: 350;
  font-family: "Montserrat", sans-serif;
  display: flex;
  padding-bottom: 1px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  width: 70px;
  background-color: whitesmoke;
  color: black;
  border: 1px solid grey;
  border-radius: 5px;
}
.selectedScaleButton {
  background-color: rgb(64, 119, 196);
  color: white;
}