.tree {
  position: absolute;
}

.tree ul {
  position: relative;
  padding: 1em 0;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
}

.tree ul::after {
  content: "";
  display: table;
  clear: both;
}

.tree li {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 1em 0.2em 0 0.5em;
}

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 1em;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
}

.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 1em;
}

.tree li div > .ruta {
  border: 1px solid #ccc;
  padding: 0.2em 1.2em;
  padding-bottom: 0.3em;
  background: rgb(64, 119, 196, 1);

  text-decoration: none;
  display: inline-block;
  border-radius: 8px;
  color: white;
  position: relative;
  top: 1px;
}

.tree li div > .ruta:hover,
.tree li div > .ruta:hover + ul li div {
  cursor: pointer;
  background: #447fd1;
  color: #fff;
  border: 1px solid #4077c4;
}

.tree li div > .ruta:hover + ul li::after,
.tree li div > .ruta:hover + ul li::before,
.tree li div > .ruta:hover + ul::before,
.tree li div > .ruta:hover + ul ul::before {
  border-color: #4077c4;
}
