.headerContainer {
  display: flex;
  margin-bottom: 12px;
}
.headerContainer div {
  border-bottom: 1px solid black;
  font-size: 14px;
  font-weight: 600;
  width: 170px;
  min-width: 170px;
}
.headerContainer div:nth-last-of-type(2) {
  padding-left: 2px;
  width: 40px;
  min-width: 40px;
}
.headerContainer div:last-child {
  padding-left: 12px;
  width: 35px;
  min-width: 35px;
}