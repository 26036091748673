.loginPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  color: #777;
  text-align: center;
  min-height: calc(100vh - 90px);
}

i {
  margin-right: 10px;
}

.loginFormContainer {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 460px;
  /* height: 230px; */
  min-height: 230px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.loginForm>div>input {
  font-size: 15px;
  height: 40px;
  width: 300px;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #333;
  margin: 5px;
  padding-left: 2px;
}

.loginForm>div>input:focus {
  outline-width: 0px;
  color: #333;
}

::-webkit-input-placeholder {
  color: #aaa;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.loginButton {
  border-radius: 5px;
  border: 1px solid #777;
  margin-left: 28px;
  margin-top: 16px;
  height: 37px;
  width: 305px;
  cursor: pointer;
  color: #eee;
  font-size: 16px;
  font-weight: 350;
  font-family: "Montserrat", sans-serif;
  padding: 8px 0px;
  text-decoration: none;
  background-color: rgb(47, 100, 171);
}

.loginButton:hover {
  background-color: rgb(47, 100, 171, 0.9);
}

.loginButton:active {
  outline-width: 0px;
  position: relative;
}

.loginButton:focus {
  outline-width: 0px;
}

.regMessageContainer {
  position: relative;
  left: 15px;
  top: -5px;
  width: 250px;
  font-size: 14px;
}

.regText {
  display: flex;
  margin-left: 75px;
  flex-wrap: wrap;
}

.errorTextRegPage {
  display: flex;
  /* height: 30px; */
  justify-content: center;
  margin-right: 25px;
}

.regLink {
  color: rgb(47, 100, 171, 0.9);
  margin-left: 3px;
  text-decoration: underline;
  cursor: pointer;
}

.spinner {
  position: relative;
  top: 8px;
  left: 70px;
  ;
}

.errorMessages {
  margin-left: 5px;
  height: 20px;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");