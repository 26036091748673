.portfolioContainer {
  position: relative;
  top: 12px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  /* background: rgb(18, 92, 99); */
  background: rgb(20, 106, 114);
  width: 705px;
  border-radius: 10px 10px 0px 0px;
}

.portfolioTextContainer {
  letter-spacing: 1.5px;
  font-weight: 350;
  padding-left: 20px;
  display: flex;
  width: 540px;
  flex-direction: column;
  margin-bottom: 7px;
}
.portfolioIconChild {
  align-self: center;
  color: white;
  margin-left: 20px;
  margin-bottom: 7px;
  font-size: 19px;
  cursor: pointer;
}
.portfolioHeaderChild {
  margin-bottom: 3px;
  cursor: pointer;
  font-size: 19px;
  color: white;
  font-weight: 600;
}
