.riskDefDescription {
  width: 500px;
  /* width: 390px; */
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 2rem;
}

.riskGroupDescription {
  width: 500px;
  font-size: 14px;
  margin-top: 5px;
}

.riskDefSubTitle {
  /* margin-top: 4rem; */
  margin-bottom: 50px;
}

.riskDefExplained {
  margin-top: 15px;
  font-size: 11px;
  width: 390px;
  display: flex;
  justify-content: space-between;
  color: #777;
}

.riskDefContainer span {
  width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-left: 1.5rem;
}

.riskDefCircle {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 0.8rem;
  color: #777;
  border: thin solid #777;
  border-radius: 50%;
  cursor: pointer;
}

.riskDefDesc {
  color: #777;
  font-size: 0.8rem;
}

.riskDefDesc::before {
  content: " - ";
}

.riskDefDesc::after {
  content: " - ";
}

.riskGroupContainer {
  position: absolute;
  top: 355px;
  left: 600px;
}

.riskGroupHeader {
  width: 440px;
  display: grid;
  grid-template-columns: 4rem 2fr 3fr;
  grid-template-rows: auto;
  margin-top: 3rem;
  margin-left: 0.5rem;
}

.riskGroupContent span {
  width: 440px;
  display: grid;
  grid-template-columns: 4rem 2fr 3fr;
  grid-template-rows: auto;
  justify-items: flex-start;
  align-items: center;
  margin-left: 0.5rem;
}

.riskGroupDesc {
  color: #777;
  font-size: 0.8rem;
  margin-top: 5px;
  cursor: pointer;
}

.riskGroupBox {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 1rem;
  text-align: center;
  color: rgb(0, 0, 0, 0.45);
  border-radius: 8px;
  margin-top: 5px;
}

/* General Colours */
.greenDarkColor {
  background-color: rgb(84, 180, 122);
}
.orangeDarkColor {
  background-color: rgb(231, 37, 37);
}
.greenLightColor {
  background-color: rgb(106, 226, 154);
}
.orangeLightColor {
  background-color: rgb(254, 125, 53);
}
.yellowColor {
  background-color: rgb(252, 255, 102);
}

.main {
  position: relative;
  display: inline-block;
}

.sub {
  display: none;
  /* Style the dialogue tooltip */
  overflow: auto;
  width: 10rem;
  height: auto;
  line-height: 20px;
  text-align: left;
  font-size: 14px;
  background-color: white;
  /* background-color: rgb(64, 119, 196); */
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  padding: 4px 5px;

  /* Position the dialogue tooltip */
  position: absolute;
  z-index: 1;
  top: 0%;
  left: 60px;
}

.main:hover .sub {
  display: block;
  opacity: 1;
}

/* Style modal */
.addVerifyCheckButton {
  /* position: absolute; */
  /* top: 650px; */
  /* left: 400px; */
  font-size: 15px;
  width: 180px;
  color: white;
  border: 1px solid rgb(200, 200, 200, 0.7);
  border-radius: 15px;
  /* background-color: rgb(80, 80, 80, 0.85); */
  background-color: rgb(23, 80, 155);
  height: 29px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 20px;
  cursor: pointer;
}

.addVerifyCheckButton:hover {
  /* background-color: rgb(47, 100, 171, 0.95); */
  /* background-color: rgb(80, 80, 80, 0.95); */
  background-color: rgb(23, 80, 155, 0.85);
}

.addVerifyCheckButton:active {
  outline-width: 0px;
  /* position: relative; */
}
.addVerifyCheckButton:focus {
  outline-width: 0px;
}

.commentVerifyCheck {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  /* background-color: rgb(39, 94, 167); */
  /* border: 1px whitesmoke solid; */
  border-radius: 15px;
  padding: 15px 20px;
  width: 300px;
  color: black;
  /* color: white; */
  text-align: center;
  padding-bottom: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.commentVerifyCheck h4 {
  /* font-size: 7px; */
  padding: 2px;
  margin: 2px;
}
.fieldRow {
  margin-bottom: 15px;
  outline-width: 0px;
}

.descriptionInput {
  font-family: Arial;
  margin-top: 10px;
  /* padding-left: 5px;
    margin-left: 10px; */
  height: 95px;
  width: 270px;
  /* border: 0.5px solid black; */
  resize: none;
  line-height: 20px;
  /* outline-width: 0px; */
}
