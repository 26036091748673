.contextMenu {
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    z-index: 100;
    padding: 5px 0;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.contextMenuItem {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(116, 116, 116);
    margin-bottom: 1px;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.contextMenuItem:hover {
    background-color: rgb(70, 115, 173);
}
