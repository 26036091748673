.categoryChart {
    width: 462px;
    min-width: 462px;
    height: 250px;
    /* flex: 33% 10% 10%; */
    /* margin-left: 75px; */
    margin-right: 35px;
    cursor: pointer;
    /* border: thin solid black; */
    /* background-color: antiquewhite; */
    /* border-radius: 15px; */
    margin-top: 40px;
    background-color: rgb(246, 246, 246);
    /* margin-bottom: 40px; */
    padding: 20px 20px 20px 30px;
    border: rgb(173, 173, 173) solid 0.1rem;
    border-radius: 15px;
    box-shadow: rgb(158, 158, 158) 0.5px 2px;
    /* flex-basis: 25%; */
    /* margin-bottom: 75px; */
    /* font-size: 33px; */
    /* font-family: Arial, Helvetica, sans-serif; */
    /* display: flex;
    flex-direction: column; */
}

.step {
    /* font-family: Arial, Helvetica, sans-serif; */
    /* font-size: 33px; */
    user-select: none;
    cursor: pointer;
    color: red;
}

.step .stepDetails {
    visibility: hidden;
}

.step:hover .stepDetails {
    font-size: 12.5px;
    /* color: rgb(221, 13, 13); */
    /* font-family: Arial, Helvetica, sans-serif; */
    fill: rgb(39, 39, 39);
    visibility: visible;
}


@keyframes circle--animation {
    0% {
      stroke-dashoffset: 75;
    }
    
    50% {
      stroke-dashoffset: 280;
    }
  }
  
  /* // Long form animation rules applied to circle element. */
  circle {
    animation-duration: 1.4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-name: circle--animation;
  
  }